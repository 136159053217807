import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid, MenuItem } from "@mui/material";
import SubHeader from '../../../components/SubHeader';
import appconfig from '../../../appConfig'
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { tokenRequest } from "../../../auth/authConfig";
import TextField from "../../../components/TextField";
import CustomDatePicker from "../../../components/DatePicker";
import SelectorTextField from "../../../components/SelectorTextField";
import StyledSwitch from '../../../components/StyledSwitch';
import StyledDialog from '../../../components/Dialog';
import AutoCompleteTextField from "../../../components/AutoComplete";
import { GetClientGatewayMetaData, CreateClientGatewayRequest, GetClientGatewayById, GetClientGatewayFormSources } from "../../../services/client-gateway-service";
import { GetClientFileById } from "../../../services/client-file.service"
import { toast } from "react-toastify";
import _ from "lodash";
import Backdrop from '../../../components/elements/Loader';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useNavigationParameters } from "../../../hooks/useNavigationParameters";
import * as Constants from "../../../helper/constants";
import Divider from '@mui/material/Divider';

function ClientGatewayForm() {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [appendExistingNavigationParameters, removeDuplicatedNavigationParameters, prepareNavigationParameters] = useNavigationParameters();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [clientGatewayid, setClientGatewayid] = useState(searchParams.get("clientgatewayid"));
    const [clientFileId, setClientFileId] = useState(searchParams.get("clientFileId"));
    const [backPage, setBackPage] = useState(searchParams.get("page"));
    const [crmId, setCrmId] = useState(searchParams.get("crmId"));
    const [firstNameFromSearchParams, setFirstNameFromSearchParams] = useState(searchParams.get("firstname"));
    const [middleNameFromSearchParams, setMiddleNameFromSearchParams] = useState(searchParams.get("middlename"));
    const [lastNameFromSearchParams, setLastNameFromSearchParams] = useState(searchParams.get("lastname"));
    const [dateOfBirthFromSearchParams, setDateOfBirthFromSearchParams] = useState(searchParams.get("dateofbirth"));

    const [readOnly, setReadOnly] = useState(false);
    const [formType, setFormType] = useState("");
    const [formTypes, setFormTypes] = useState([]);
    const [name, setName] = useState("");
    const [counselName, setCounselName] = useState("");
    const [clientConsented, setIsClientConsented] = useState(false); // set default client consent value to false
    const [notApplicableClientConsent, setNotApplicableClientConsent] = useState(false); // set default value to N/A of client consent
    // TODO: Move clientConsentOptions to the database
    const clientConsentOptions = ['No', 'Yes', 'N/A'];
    const clientConsentOptionsMapping = clientConsentOptions.map(value => ({ name: value, value })); // client consent options mapping
    const [clientConsentOptionsDisplay, setClientConsentOptionsDisplay] = useState(""); // set default client consent selection to empty
    const [isContatcInfoConsented, setIsContatcInfoConsented] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [isHasFixedAddress, setHasFixedAddress] = useState(false); // set default selection of fixed address to 'No'
    const [address, setAddress] = useState("");
    const [addressMaxLengthError, setAddressMaxLengthError] = useState(false);
    const [city, setCity] = useState("");
    const [cityMaxLengthError, setCityMaxLengthError] = useState("");
    const [cities, setCities] = useState([]);
    const [contactNotes, setContactNotes] = useState("");
    const [contactNotesMaxLengthError, setContactNotesMaxLengthError] = useState(false);
    const [isFinancialsRequired, setFinancialsRequired] = useState(true); // set default selection of financials required to 'Yes'
    const [familySize, setFamilySize] = useState(""); // initialize family size field as empty
    const [familySizeLimitError, setFamilySizeLimitError] = useState("");
    const [isClientOnAISH, setClientOnAISH] = useState(false); // set default client on AISH to 'No'
    const [maritalStatus, setMaritalStatus] = useState(""); // set default marital status to 'None'
    // TODO: Move marital status options to the database
    const maritalStatusOptions = ['Single', 'Common Law/Married', 'Separated'];
    const maritalStatusOptionsMapping = maritalStatusOptions.map(value => ({ name: value, value })); // marital status options mapping
    const [showPartnerQuestions, setShowPartnerQuestions] = useState(false);
    const [isHaveAssets, setHaveAssets] = useState(true); // set default to 'Yes' for Client have assets
    const [realEstateEquityValue, setRealEstateEquityValue] = useState(""); // initialize real estate equity value as empty
    const [realEstateEquityValueLimitError, setRealEstateEquityValueLimitError] = useState("");
    const [vehicleEquityValue, setVehicleEquityValue] = useState(""); // initialize vehicle equity value as empty
    const [vehicleEquityValueLimitError, setVehicleEquityValueLimitError] = useState("");
    const [liquidAssetsEquityValue, setLiquidAssetsEquityValue] = useState(""); // initialize liquid assets equity value as empty
    const [liquidAssetsEquityValueLimitError, setLiquidAssetsEquityValueLimitError] = useState("");
    const [thirtyDayIncome, setThirtyDayIncome] = useState(""); // initialize 30 day net income value as empty to have a placeholder of "0.00"
    const [thirtyDayIncomeLimitError, setThirtyDayIncomeLimitError] = useState("");
    const [twelveMonthIncome, setTwelveMonthIncome] = useState(""); // initialize twelve month net income value as empty
    const [twelveMonthIncomeLimitError, setTwelveMonthIncomeLimitError] = useState("");
    const [partnerThirtyDayIncome, setPartnerThirtyDayIncome] = useState(""); // initialize partner 30 day net income value as empty
    const [partnerThirtyDayIncomeLimitError, setPartnerThirtyDayIncomeLimitError] = useState("");
    const [partnerTwelveMonthIncome, setPartnerTwelveMonthIncome] = useState(""); // initialize partner 12 month net income value as empty
    const [partnerTwelveMonthIncomeLimitError, setPartnerTwelveMonthIncomeLimitError] = useState("");
    const [isClientHaveDeductions, setClientHaveDeductions] = useState(true); // set default client have deductions
    const [childSupportPaymentAmounts, setChildSupportPaymentAmounts] = useState(""); // initialize Monthly Child Support Payment Amounts as empty
    const [childSupportPaymentAmountsLimitError, setChildSupportPaymentAmountsLimitError] = useState(false);
    const [spousalSupportPaymentAmounts, setSpousalSupportPaymentAmounts] = useState(""); // initialize Monthly Spousal Support Payment Amounts as empty
    const [spousalSupportPaymentAmountsLimitError, setSpousalSupportPaymentAmountsLimitError] = useState(false);
    const [childCarePaymentAmounts, setChildCarePaymentAmounts] = useState(""); // initialize Monthly Child Care Payment Amounts as empty
    const [childCarePaymentAmountsLimitError, setChildCarePaymentAmountsLimitError] = useState(false);
    const [financialNotes, setFinancialNotes] = useState(""); // initialize financial comments as empty
    const [financialNotesMaxLengthError, setFinancialNotesMaxLengthError] = useState(false);
    const [havePreferredCounsel, setHavePreferredCounsel] = useState(false);
    const [preferredCounsel, setPreferredCounsel] = useState("");
    const [preferredCounselError, setPreferredCounselError] = useState(false);
    const [preferredCounselMaxLengthError, setPreferredCounselMaxLengthError] = useState(false);
    const [indigenousAncestry, setIndigenousAncestry] = useState("");
    const [indigenousAncestries, setIndigenousAncestries] = useState([]);
    const [isInCustody, setIsInCustody] = useState(false);
    const [theORCANumber, setORCANumber] = useState(""); // initialize the ORCA Number to empty
    const [theORCANumberLimitError, setORCANumberLimitError] = useState(false);
    const [custodyLocation, setCustodyLocation] = useState([]);
    const [custodyLocations, setCustodyLocations] = useState([]);
    const [helpNeededOnAllActiveCharges, setHelpNeededOnAllActiveCharges] = useState(true);
    const yesOrNoSelectionList = [{ selectionItemId: "0", label: "Yes", value: true }, { selectionItemId: "1", label: "No", value: false },];
    const [confirmLOIforCharges, setConfirmLOIforCharges] = useState(false);
    const [isConsentedDCOrdering, setIsConsentedDCOrdering] = useState(false);
    const [nextCourtDate, setNextCourtDate] = useState("");
    const [isNextCourtDateInvalid, setIsNextCourtDateInvalid] = useState(false);
    const [docketNumber, setDocketNumber] = useState("");
    const [docketMaxLengthError, setDocketMaxLengthError] = useState(false);
    const [courtLocation, setCourtLocation] = useState("");
    const [arryOfInputValues, setArryOfInputValues] = useState([]);
    const [courtLocations, setCourtLocations] = useState([]);
    const [courtRoom, setCourtRoom] = useState("");
    const [generalComments, setGeneralComments] = useState("");
    const [generalCommentsMaxLengthError, setGeneralCommentsMaxLengthError] = useState(false);
    const [isCurrentCounselConsented, setIsCurrentCounselConsented] = useState(false);
    const [activeCertificateNumber, setActiveCertificateNumber] = useState("");
    const [activeCertificateNumberError, setActiveCertificateNumberError] = useState(false);
    const [activeCertificateNumberMaxLengthError, setActiveCertificateNumberMaxLengthError] = useState(false);
    const [openContactInfoDialog, setOpenContactInfoDialog] = useState(false);
    const [openDCOrderingDialog, setOpenDCOrderingDialog] = useState(false);
    const [openHelpNeededActiveChargesDialog, setOpenHelpNeededActiveChargesDialog] = useState(false);
    const [courtRoomMaxLengthError, setCourtRoomMaxLengthError] = useState(false);
    const [arryOfInputCustodyLocations, setArryOfInputCustodyLocations] = useState([]);
    const [tooltipText, setTooltipText] = useState("Form type is mandatory to submit the form.");
    const [isDataReady, setIsDataReady] = useState(false);
    const [trackEvents, setTrackEvents] = useState("");
    const [formSource, setFormSource] = useState(null);
    const [getSubmittedFormSource, setSubmittedFormSource] = useState(null);
    const appInsights = useAppInsightsContext();
    const trackEventsInsights = useTrackEvent(appInsights, "Client Gateway : ", trackEvents);

    useEffect(() => {
        trackEventsInsights();
    }, [trackEvents, trackEventsInsights]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsDataReady(false);
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    setName(response.account && response.account.name && response.account.name.length > 101 ? response.account.name.substring(0, 100) : response.account.name);
                    setCounselName(response.account.name);
                    fetchInitialData(response.accessToken);
                }
            });
        }
    }, []);

    const fetchInitialData = async (token) => {
        GetClientGatewayFormSources(token).then(data => {
            const currentRole = account.idTokenClaims.roles[0]
            const formSourceId = data.find(d => d.label == currentRole)
            if (formSourceId && formSourceId.clientGatewayFormSourceId) {
                setFormSource(formSourceId.clientGatewayFormSourceId)
            } else {
                setFormSource(851160003)
            }
        });
        const clientData = await GetClientFileById(clientFileId, token);

        const metaData = await GetClientGatewayMetaData(token);
        if (metaData) {
            setCourtLocations(metaData.courtLocations);
            setFormTypes(metaData.formTypes);

            setCustodyLocations(metaData.custodyLocations);
            setIndigenousAncestries(metaData.isIndeginous);
        }
        else {
            toast.error("An error occurred while loading data.", { position: "top-center", autoClose: 5000, theme: "colored" });
            appInsights.trackException({ error: new Error('An error occurred while loading data.'), severityLevel: SeverityLevel.Error });
            //alert("An error occurred while loading data.");
        }

        if (clientData) {
            setFirstName(clientData.clientBasicInformation.firstName.length > 101 ? clientData.clientBasicInformation.firstName.substring(0, 100) : clientData.clientBasicInformation.firstName);
            setLastName(clientData.clientBasicInformation.lastName.length > 101 ? clientData.clientBasicInformation.lastName.substring(0, 100) : clientData.clientBasicInformation.lastName);
            setDateOfBirth(clientData.clientBasicInformation.dateOfBirth)
            setPhoneNumber(clientData.clientContactDetails && clientData.clientContactDetails.phoneNumber && clientData.clientContactDetails.phoneNumber.length > 101 ? clientData.clientContactDetails.phoneNumber.substring(0, 100) : clientData.clientContactDetails.phoneNumber);
            setMobileNumber(clientData.clientContactDetails.mobileNumber);
            setEmail(clientData.clientContactDetails && clientData.clientContactDetails.email && clientData.clientContactDetails.email.length > 101 ? clientData.clientContactDetails.email.substring(0, 100) : clientData.clientContactDetails.email);
        }

        if (clientGatewayid && parseInt(clientGatewayid) > 0) {
            setReadOnly(true);
            var data = await GetClientGatewayById(clientGatewayid, token);
            setInitialClientGateway(data, metaData.courtLocations, metaData.custodyLocations, metaData.formTypes, metaData.isIndeginous);
            setTrackEvents(JSON.stringify(data));
            appInsights.trackTrace({ message: JSON.stringify(data), severityLevel: SeverityLevel.Information });
            setIsDataReady(true);
        } else {
            setIsDataReady(true);
        }

    }

    useEffect(() => { if (isContatcInfoConsented === false) setOpenContactInfoDialog(true); }, [isContatcInfoConsented]);

    useEffect(() => { if (helpNeededOnAllActiveCharges === false) setOpenHelpNeededActiveChargesDialog(true); }, [helpNeededOnAllActiveCharges]);

    const setInitialClientGateway = (gatewayObject, courtLocationList, custodyLocationList, formTypesList, indigenousAncestriesList) => {

        setName(gatewayObject.laa_DCName.length > 101 ? gatewayObject.laa_DCName.substring(0, 100) : gatewayObject.laa_DCName)
        setIsCurrentCounselConsented(gatewayObject.laa_CurrentCounselConsent);
        setActiveCertificateNumber(gatewayObject.laa_ActiveCertificateNumber);
        setIsClientConsented(gatewayObject.laa_ClientConsent);
        setNotApplicableClientConsent(gatewayObject.laa_NotApplicableClientConsent);

        if (gatewayObject.laa_ClientConsent == false && gatewayObject.laa_NotApplicableClientConsent == false) {
            setClientConsentOptionsDisplay("No");
        } else if (gatewayObject.laa_ClientConsent == true && gatewayObject.laa_NotApplicableClientConsent == false) {
            setClientConsentOptionsDisplay("Yes");
        } else if (gatewayObject.laa_ClientConsent == false && gatewayObject.laa_NotApplicableClientConsent == true) {
            setClientConsentOptionsDisplay("N/A");
        }

        setIsContatcInfoConsented(gatewayObject.laa_IsClientContactAvailable);
        setAddress(gatewayObject.laa_Address);
        setContactNotes(gatewayObject.laa_ContactComments);
        setHasFixedAddress(gatewayObject.laa_HasFixedAddress);
        setFinancialsRequired(gatewayObject.laa_AreNewOrUpdateFinancialsRequired);
        setFamilySize(gatewayObject.laa_FamilySize);
        setMaritalStatus(gatewayObject.laa_MaritalStatus);
        setClientOnAISH(gatewayObject.laa_IsClientOnAish);
        setThirtyDayIncome(gatewayObject.laa_ThirtyDayIncome);
        setTwelveMonthIncome(gatewayObject.laa_TwelveMonthIncome);
        setPartnerThirtyDayIncome(gatewayObject.laa_PartnerThirtyDayIncome);
        setPartnerTwelveMonthIncome(gatewayObject.laa_PartnerTwelveMonthIncome);
        setShowPartnerQuestions(gatewayObject.laa_MaritalStatus === maritalStatusOptions[1]);
        setHaveAssets(gatewayObject.laa_HasAssets);
        setRealEstateEquityValue(gatewayObject.laa_RealEstateEquityValue);
        setVehicleEquityValue(gatewayObject.laa_VehicleEquityValue);
        setLiquidAssetsEquityValue(gatewayObject.laa_LiquidAssetsEquityValue);
        setClientHaveDeductions(gatewayObject.laa_HasDeductions);
        setChildSupportPaymentAmounts(gatewayObject.laa_ChildSupportPaymentAmounts);
        setSpousalSupportPaymentAmounts(gatewayObject.laa_SpousalSupportPaymentAmounts);
        setChildCarePaymentAmounts(gatewayObject.laa_ChildCarePaymentAmounts);
        setFinancialNotes(gatewayObject.laa_FinancialNotes);
        setHavePreferredCounsel(gatewayObject.laa_HasPreferredCounsel);
        setCity(gatewayObject.laa_City);

        setPreferredCounsel(gatewayObject.laa_PreferredCounsel);
        setIsInCustody(gatewayObject.laa_InCustody);
        setORCANumber(gatewayObject.laa_OrcaNumber);
        setHelpNeededOnAllActiveCharges(gatewayObject.laa_HelpNeededOnAllActiveCharges);
        setConfirmLOIforCharges(gatewayObject.laa_LikelihoodofImprisonment);
        setIsConsentedDCOrdering(gatewayObject.laa_ConsentforDisclosure);
        setNextCourtDate(Date.parse(gatewayObject.laa_NextCourtDate));
        setDocketNumber(gatewayObject.laa_DocketNumber);
        setCourtRoom(gatewayObject.laa_Courtroom);
        setGeneralComments(gatewayObject.laa_GeneralComments);
        setSubmittedFormSource(gatewayObject.laa_FormSource);

        if (gatewayObject.laa_FormType) {
            let selectedFormType = _.filter(formTypesList, { 'value': gatewayObject.laa_FormType.toString() });
            setFormType(selectedFormType[0]);
        }

        if (gatewayObject.laa_CustodyLocation) {
            let selectedCustodyLocation = _.filter(custodyLocationList, { 'value': gatewayObject.laa_CustodyLocation.toString() });
            setCustodyLocation(selectedCustodyLocation[0]);
            setArryOfInputCustodyLocations(selectedCustodyLocation[0]);
        }

        if (gatewayObject.laa_IsIndigenous) {
            let selectedIsIndigenous = _.filter(indigenousAncestriesList, { 'value': gatewayObject.laa_IsIndigenous.toString() });
            setIndigenousAncestry(selectedIsIndigenous[0]);
        }

        if (gatewayObject.laa_CourtLocation) {
            const fecthInitialCourtLocations = [];
            const fecthArrayOfCourtLocations = [];
            var locationArray = gatewayObject.laa_CourtLocation.split(",");
            locationArray.forEach((courtLocation) => {
                let selectedLocation = _.filter(courtLocationList, { 'value': courtLocation.toString() });
                fecthInitialCourtLocations.push(selectedLocation[0]);
                fecthArrayOfCourtLocations.push(selectedLocation[0].label);
            });
            setCourtLocation(fecthInitialCourtLocations);
            setArryOfInputValues(fecthArrayOfCourtLocations);
        }
    }

    //#region onChangeEvents
    const onFormTypeChanged = (event) => {
        const selectedFormType = formTypes.find(
            (object) => object?.label === event.target.value
        );
        setFormType(selectedFormType);

        setTooltipText(selectedFormType.label);

    };
    // check selection of client consent and set display for selections
    const onClientConsentChanged = (event) => {
        const clientConsentUpdated = clientConsentOptionsMapping.find(
            (object) => object?.value === event.target.value
        );

        if (clientConsentUpdated.value === 'No') {
            setIsClientConsented(false);
            setNotApplicableClientConsent(false);
        } else if (clientConsentUpdated.value === 'Yes') {
            setIsClientConsented(true);
            setNotApplicableClientConsent(false);
        } else if (clientConsentUpdated.value === 'N/A') {
            setIsClientConsented(false);
            setNotApplicableClientConsent(true);
        }
        setClientConsentOptionsDisplay(clientConsentUpdated.value);
    };
    const onContatcInfoConsentChanged = (event) => setIsContatcInfoConsented(event.target.checked);
    const onAddressChanged = (event) => {
        if (event.target.value.length < 101) {
            setAddressMaxLengthError(false);
            setAddress(event.target.value);
        } else {
            setAddressMaxLengthError(true);
        }
    };
    // TODO: Generate a list with all cities in Canada and retrive data from the json file
    const onCityChanged = (event) => {
        if (event.target.value && event.target.value.length < 101) {
            setCityMaxLengthError(false);
            setCity(event.target.value);
        } else {
            setCityMaxLengthError(true);
        }
    };
    
    const onContactNotesChanged = (event) => {
        if (event.target.value.length < 256) {
            setContactNotesMaxLengthError(false);
            setContactNotes(event.target.value);
        } else {
            setContactNotesMaxLengthError(true);
        }
    };
    const isHasFixedAddressChanged = (event) => {
        setHasFixedAddress(event.target.checked);  // check if there is change of the selection of has fixed address
        if (!event.target.checked) {
            setCity("");
            setAddress("");
            setCityMaxLengthError("");
            setAddressMaxLengthError("");
        }
    }
    const isFiancialRequiredChanged = (event) => setFinancialsRequired(event.target.checked);  // check if there is change of the selection of financials required
    // check type of input of family size to avoid non-integer & range of the input 
    const onFamilySizeChanged = (event) => {
        if (!isNaN(+event.target.value) && event.target.value <= 99 && event.target.value >= 1) {
            setFamilySizeLimitError(false);
            setFamilySize(event.target.value);
        } else {
            setFamilySize();
            setFamilySizeLimitError(true);
        }
    }
    // check selection of marital status
    const onMaritalStatusChanged = (event) => {
        const maritalStatusUpdated = maritalStatusOptionsMapping.find(
            (object) => object?.value === event.target.value
        );
        setMaritalStatus(maritalStatusUpdated.value);
        setShowPartnerQuestions(maritalStatusUpdated.value === maritalStatusOptions[1]);
    };
    const isClientOnAISHChanged = (event) => {
        setClientOnAISH(event.target.checked); // check if there is change of the selection of client on AISH
        if (event.target.checked == true) {
            setClientHaveDeductions(false);
        }
    }
    const isHaveAssetsChanged = (event) => setHaveAssets(event.target.checked); // check if there is change of the selection of client have assets
    // check type & range of input of real estate equity value
    const onRealEstateEquityValueChange = (event) => {
        if (!isNaN(+event.target.value) && event.target.value <= 922337203685477.00 && event.target.value >= 0.00) {
            setRealEstateEquityValueLimitError(false);
            setRealEstateEquityValue(event.target.value);
        } else {
            setRealEstateEquityValue();
            setRealEstateEquityValueLimitError(true);
        }
    }
    // check type & range of input of vehicle equity value
    const onVehicleEquityValueChange = (event) => {
        if (!isNaN(+event.target.value) && event.target.value <= 922337203685477.00 && event.target.value >= 0.00) {
            setVehicleEquityValueLimitError(false);
            setVehicleEquityValue(event.target.value);
        } else {
            setVehicleEquityValue();
            setVehicleEquityValueLimitError(true);
        }
    }
    // check type & range of input of liquid assets value
    const onLiquidAssetsEquityValueChange = (event) => {
        if (!isNaN(+event.target.value) && event.target.value <= 922337203685477.00 && event.target.value >= 0.00) {
            setLiquidAssetsEquityValueLimitError(false);
            setLiquidAssetsEquityValue(event.target.value);
        } else {
            setLiquidAssetsEquityValue();
            setLiquidAssetsEquityValueLimitError(true);
        }
    }
    // check type of input of 30 day net income to avoid non-integer & range of the input 
    const onThirtyDayIncomeChanged = (event) => {
        if (!isNaN(+event.target.value) && event.target.value <= 922337203685477.00 && event.target.value >= 0.00) {
            setThirtyDayIncomeLimitError(false);
            setThirtyDayIncome(event.target.value);
        } else {
            setThirtyDayIncome();
            setThirtyDayIncomeLimitError(true);
        }
    }
    // check type of input of 12 month income to avoid non-integer & range of the input 
    const onTwelveMonthIncomeChanged = (event) => {
        if (!isNaN(+event.target.value) && event.target.value <= 922337203685477.00 && event.target.value >= 0.00) {
            setTwelveMonthIncomeLimitError(false);
            setTwelveMonthIncome(event.target.value);
        } else {
            setTwelveMonthIncome();
            setTwelveMonthIncomeLimitError(true);
        }
    }
    // check type of input of partner 30 day net income to avoid non-integer & range of the input 
    const onPartnerThirtyDayIncomeChanged = (event) => {
        if (!isNaN(+event.target.value) && event.target.value <= 922337203685477.00 && event.target.value >= 0.00) {
            setPartnerThirtyDayIncomeLimitError(false);
            setPartnerThirtyDayIncome(event.target.value);
        } else {
            setPartnerThirtyDayIncome();
            setPartnerThirtyDayIncomeLimitError(true);
        }
    }
    // check type of input of partner 12 month income to avoid non-integer & range of the input 
    const onPartnerTwelveMonthIncomeChanged = (event) => {
        if (!isNaN(+event.target.value) && event.target.value <= 922337203685477.00 && event.target.value >= 0.00) {
            setPartnerTwelveMonthIncomeLimitError(false);
            setPartnerTwelveMonthIncome(event.target.value);
        } else {
            setPartnerTwelveMonthIncome();
            setPartnerTwelveMonthIncomeLimitError(true);
        }
    }
    const isClientHaveDeductionsChanged = (event) => setClientHaveDeductions(event.target.checked); // check if there is change of the selection of client have deductions
    // check type & range of input of Monthly Child Support Payment Amounts
    const onChildSupportPaymentAmountsChange = (event) => {
        if (!isNaN(+event.target.value) && event.target.value <= 922337203685477.00 && event.target.value >= 0.00) {
            setChildSupportPaymentAmountsLimitError(false);
            setChildSupportPaymentAmounts(event.target.value);
        } else {
            setChildSupportPaymentAmounts();
            setChildSupportPaymentAmountsLimitError(true);
        }
    }
    // check type & range of input of Monthly Spousal Support Payment Amounts
    const onSpousalSupportPaymentAmountsChange = (event) => {
        if (!isNaN(+event.target.value) && event.target.value <= 922337203685477.00 && event.target.value >= 0.00) {
            setSpousalSupportPaymentAmountsLimitError(false);
            setSpousalSupportPaymentAmounts(event.target.value);
        } else {
            setSpousalSupportPaymentAmounts();
            setSpousalSupportPaymentAmountsLimitError(true);
        }
    }
    // check type & range of input of Monthly Child Care Payment Amounts
    const onChildCarePaymentAmountsChange = (event) => {
        if (!isNaN(+event.target.value) && event.target.value <= 922337203685477.00 && event.target.value >= 0.00) {
            setChildCarePaymentAmountsLimitError(false);
            setChildCarePaymentAmounts(event.target.value);
        } else {
            setChildCarePaymentAmounts();
            setChildCarePaymentAmountsLimitError(true);
        }
    }
    // check input characters limits of financial comments
    const onFinancialNotesChanged = (event) => {
        if (event.target.value.length < 301) {
            setFinancialNotesMaxLengthError(false);
            setFinancialNotes(event.target.value);
        } else {
            setFinancialNotesMaxLengthError(true);
        }
    };
    const onHavePreferredCounselChanged = (event) => setHavePreferredCounsel(event.target.checked);
    const onPreferredCounselChanged = (event) => {
        if (event.target.value.length < 101) {
            if (event.target.value.length > 0) setPreferredCounselMaxLengthError(false);
            setPreferredCounselError(false);
            setPreferredCounsel(event.target.value);
        } else {
            setPreferredCounselMaxLengthError(true);
        }
    }
    const onIndigenousAncestryChanged = (event) => {
        const indigenousAncestryUpdated = indigenousAncestries.find(
            (object) => object?.label === event.target.value
        );
                setIndigenousAncestry(indigenousAncestryUpdated);
    };


    const onInCustodyChanged = (event) => {
        setIsInCustody(event.target.checked);
        setORCANumber("");
        setCustodyLocation([]);
        setArryOfInputCustodyLocations([]);
    }
    // check type of input of ORCA Number to avoid non-integer & range of the input
    const onORCANumberChange = (event) => {
        if (event.target.value === "" || (event.target.value <= 999999999999 && event.target.value >= 1)) {
            setORCANumberLimitError(false);
            setORCANumber(event.target.value);
        } else if (isNaN(event.target.value)) {
            setORCANumberLimitError(true);
            setORCANumber(event.target.value);
        }
    }
    const onCustodyLocationChanged = (event, InputValue) => {
        setArryOfInputCustodyLocations(InputValue);
        let seletcedOption = custodyLocations.find((option) => option?.label == InputValue)
        setCustodyLocation(seletcedOption);
    };
    const onHelpNeededOnAllActiveChargesChanged = (event) => setHelpNeededOnAllActiveCharges(event.target.checked);
    const onconfirmLOIforChargesChanged = (event) => setConfirmLOIforCharges(event.target.checked);
    const onConsentedDCOrderingChanged = (event) => setIsConsentedDCOrdering(event.target.checked);
    const onNextCourtDateChanged = (date) => {
        if (date != null) {
            if ((new Date(date)).toString() === "Invalid Date") {
                setIsNextCourtDateInvalid(true);
            } else {
                var courtDate = new Date(date).setHours(0, 0, 0);
                setNextCourtDate(new Date(courtDate));
                //setNextCourtDateError(false);
                setIsNextCourtDateInvalid(false);
            }
        } else {
            setNextCourtDate(date);
            //setNextCourtDateError(true);
            setIsNextCourtDateInvalid(false);
        }

    };
    const onDocketNumberChanged = (event) => {
        if (event.target.value.length < 101) {
            setDocketMaxLengthError(false);
            setDocketNumber(event.target.value);
        } else {
            setDocketMaxLengthError(true);
        }
    };

    const onCourtLocationsChanged = (event, newInputValues) => {
        setArryOfInputValues(newInputValues);
        const newArrayOfObjects = [];
        newInputValues.forEach((InputValue) => {
            let seletcedOption = courtLocations.find((option) => option?.label == InputValue)
            newArrayOfObjects.push(seletcedOption);
        })
        setCourtLocation(newArrayOfObjects);
    };
    const onCourtRoomChanged = (event) => {
        if (event.target.value.length < 101) {
            setCourtRoomMaxLengthError(false);
            setCourtRoom(event.target.value);
        } else {
            setCourtRoomMaxLengthError(true);
        }
    };
    const onGeneralCommentsChanged = (event) => {
        if (event.target.value.length < 301) {
            setGeneralCommentsMaxLengthError(false);
            setGeneralComments(event.target.value);
        } else {
            setGeneralCommentsMaxLengthError(true);
        }
    };
    const onCurrentCounselConsented = (event) => setIsCurrentCounselConsented(event.target.checked);
    const onActiveCertificateNumberChanged = (event) => {
        if (event.target.value.length < 101) {
            setActiveCertificateNumberMaxLengthError(false);
            setActiveCertificateNumber(event.target.value);
            if (event.target.value.length === 0) { setActiveCertificateNumberError(true); }
            else { setActiveCertificateNumberError(false); }

        } else {
            setActiveCertificateNumberMaxLengthError(true);
        }
    };

    const onContactInfoClose = () => setOpenContactInfoDialog(false);
    const onDCOrderingClose = () => setOpenDCOrderingDialog(false);
    const onHelpNeededActiveChargesClose = () => setOpenHelpNeededActiveChargesDialog(false);

    // function is mainly to determine if enable/disable 'Submit'
    // If form can't be submitted -> disable 'Submit'&&'Submit & Close' -> enable tooltip(meaning isSubmit() is true)
    // If form is able to submit, then enable 'Submit'&&'Submit & Close' -> disable tooltip(meaning isSubmit() is false)
    const isSubmit = () => {
        let status = true; // set the function to true - can not submit
        if (
            isFormType()
            && isConsent()
        ) {
            if (hasFixedAddress() && !isFinancialsRequired && hasORCANumber() && hasCustodyLocation()) {
                status = false; // No need to fill all financials required fields
            } else {
                if (
                    hasFixedAddress()
                    && hasFamilySize()
                    && hasMaritalStatus()
                    && hasClientNetIncome()
                    && hasPartnerNetIncome()
                    && hasClientAssets()
                    && hasClientDeductions()
                    && hasORCANumber()
                    && hasCustodyLocation()
                ) {
                    status = false;
                }
            }
        }

        if (!status) {
            if (readOnly) {
                status = true;
            }
            else {
                status = false;
            }
        }

        return status;
    };

    const isFormType = () => {
        return (formType
                && ((formType.value === "851160003"
                    && isCurrentCounselConsented)
                    || (formType.value === "851160000" || formType.value === "851160001")));
    }

    const hasFixedAddress = () => {
        if (isHasFixedAddress) {
            return (city && cityMaxLengthError === false);
        } else {
            return true; // return true to escape fixed address
        }
    }

    const isConsent = () => {
        return (clientConsented !== notApplicableClientConsent
                && clientConsentOptionsDisplay !== "No"
                && clientConsentOptionsDisplay !== "");
    }

    const hasFamilySize = () => {
        return (familySize && familySizeLimitError === false);
    }

    const hasMaritalStatus = () => {
        return (maritalStatus !== "");
    }

    const hasClientNetIncome = () => {
        if (!isClientOnAISH) {
            return (thirtyDayIncome
                    && twelveMonthIncome
                    && thirtyDayIncomeLimitError === false
                    && twelveMonthIncomeLimitError === false
            );
        } else {
            return true; // return true to escape client net income
        }
    }

    const hasPartnerNetIncome = () => {
        if (!isClientOnAISH && maritalStatus === maritalStatusOptions[1]) {
            return (hasClientNetIncome() === true
                && partnerThirtyDayIncome
                && partnerTwelveMonthIncome
                && partnerThirtyDayIncomeLimitError === false
                && partnerTwelveMonthIncomeLimitError === false);
        } else if (isClientOnAISH
                    || (maritalStatus === maritalStatusOptions[0]
                        || maritalStatus === maritalStatusOptions[2])
        ) {
            return true; // return true to escape partner net income if user select other options
        }
    }

    const hasClientAssets = () => {
        if (isHaveAssets) {
            return ((vehicleEquityValue && vehicleEquityValueLimitError === false)
                    || (realEstateEquityValue && realEstateEquityValueLimitError === false)
                    || (liquidAssetsEquityValue && liquidAssetsEquityValueLimitError === false));
        } else {
            return true; // return true to escape assets if user select 'No'
        }
    }

    const hasClientDeductions = () => {
        if (isClientHaveDeductions && maritalStatus && !isClientOnAISH) {
            return ((childSupportPaymentAmounts && childSupportPaymentAmountsLimitError === false)
                    || (spousalSupportPaymentAmounts && spousalSupportPaymentAmountsLimitError === false)
                    || (childCarePaymentAmounts && childCarePaymentAmountsLimitError === false));
        } else if (!isClientHaveDeductions) {
            return true; // return true to escape deductions if user select 'No'
        }
    }

    const hasORCANumber = () => {
        return (!isInCustody || (isInCustody && hasCustodyLocation() && !theORCANumberLimitError)) ? true : false;

    }

    const hasCustodyLocation = () => {
        return (!isInCustody || (isInCustody && custodyLocation && custodyLocation.length !== 0)) ? true : false;
    }

    //#endregion
    const [saveClicked, setSaveClicked] = useState(false);
    function Save() {

        onSaveClicked(0);
    }

    function SaveAndClose() {

        onSaveClicked(1);
    }

    const validateClientGatewayDetails = () => {
        var status = true;
        if (formType != "" && formType?.value == "851160003" && activeCertificateNumber == "") { setActiveCertificateNumberError(true); status = false; }
        if (formType != "" && havePreferredCounsel == true && preferredCounsel == "") { setPreferredCounselError(true); status = false; }
        return status;
    };

    const onSaveClicked = async (option) => {

        if (clientFileId && clientFileId > 0) {

            const isValidData = validateClientGatewayDetails();
            if (isValidData) {
                setSaveClicked(true);
                var requestDetails = createRequestObject();
                setTrackEvents(JSON.stringify(requestDetails));
                appInsights.trackTrace({ message: JSON.stringify(requestDetails), severityLevel: SeverityLevel.Information });
                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then(async (response) => {
                        if (response) {
                            await CreateClientGatewayRequest(requestDetails, response.accessToken).then((clientGatewayNewId) => {
                                if (clientGatewayNewId > 0) {
                                    if (option == 0) {
                                        navigate(`${prepareNavigationParameters(Constants.CLIENTGATEWAY, new Array(clientGatewayNewId, clientFileId), null)}`);

                                        window.location.reload();
                                    }
                                    else {
                                        navigateToPage();
                                    }
                                }

                            });

                        }
                    });
                }
            }
            else
                toast.error("Cannot create a client gateway. Please try again later.", { position: "top-center", autoClose: 5000, theme: "colored" });

        }
        else {
            toast.error("Cannot create a client gateway. Client gateway needs to have an associated client file.", { position: "top-center", autoClose: 5000, theme: "colored" });

        }

    }

    const createRequestObject = () => {

        var courtLocationText = "";
        var count = 0;
        if (courtLocation && courtLocation.length > 0) {
            courtLocation.forEach(element => {
                if (count == 0) {
                    courtLocationText = element.value;
                    count++;
                }
                else {
                    courtLocationText = courtLocationText + ',' + element.value;
                }

            });
        }
        
        const request = {
            clientGatewayId: 0,
            clientFileId: parseInt(clientFileId),
            laa_FormNumber: 0,
            laa_FormType: formType ? parseInt(formType.value) : "",
            laa_FormSource: formSource,
            laa_CurrentCounselConsent: isCurrentCounselConsented,
            laa_ActiveCertificateNumber: activeCertificateNumber ? activeCertificateNumber : null,
            laa_DCName: '',
            laa_ClientConsent: clientConsented,
            laa_NotApplicableClientConsent: notApplicableClientConsent,
            laa_FirstName: firstName ? firstName : null,
            laa_LastName: lastName ? lastName : null,
            laa_DateOfBirth: dateOfBirth ? new Date(dateOfBirth) : null,
            laa_IsClientContactAvailable: isContatcInfoConsented,
            laa_PhoneNumber: phoneNumber ? phoneNumber : null,
            laa_Email: email ? email : null,
            laa_HasFixedAddress: isHasFixedAddress,
            laa_Address: address && isHasFixedAddress ? address : null,
            laa_City: city && isHasFixedAddress? city : null,
            laa_ContactComments: contactNotes ? contactNotes : null,
            laa_AreNewOrUpdateFinancialsRequired: isFinancialsRequired ? isFinancialsRequired : null,
            laa_IsClientOnAish: isClientOnAISH && isFinancialsRequired ? isClientOnAISH : null,
            laa_ClientHaveAssets: isHaveAssets && isFinancialsRequired ? isHaveAssets : null,
            laa_FamilySize: familySize && isFinancialsRequired ? familySize : null,
            laa_MaritalStatus: maritalStatus && isFinancialsRequired ? maritalStatus : null,
            laa_ThirtyDayIncome: thirtyDayIncome && !isClientOnAISH && isFinancialsRequired ? thirtyDayIncome : null,
            laa_TwelveMonthIncome: twelveMonthIncome && !isClientOnAISH && isFinancialsRequired ? twelveMonthIncome : null,
            laa_PartnerThirtyDayIncome: partnerThirtyDayIncome && !isClientOnAISH && maritalStatus === maritalStatusOptions[1] && isFinancialsRequired ? partnerThirtyDayIncome : null,
            laa_PartnerTwelveMonthIncome: partnerTwelveMonthIncome && !isClientOnAISH && maritalStatus === maritalStatusOptions[1] && isFinancialsRequired ? partnerTwelveMonthIncome : null,
            laa_HasAssets: isHaveAssets && isFinancialsRequired ? isHaveAssets : null,
            laa_RealEstateEquityValue: realEstateEquityValue && isHaveAssets && isFinancialsRequired ? realEstateEquityValue : null,
            laa_VehicleEquityValue: vehicleEquityValue && isHaveAssets && isFinancialsRequired ? vehicleEquityValue : null,
            laa_LiquidAssetsEquityValue: liquidAssetsEquityValue && isHaveAssets && isFinancialsRequired ? liquidAssetsEquityValue : null,
            laa_HasDeductions: isClientHaveDeductions && isFinancialsRequired ? isClientHaveDeductions : null,
            laa_ChildSupportPaymentAmounts: childSupportPaymentAmounts && isClientHaveDeductions && isFinancialsRequired ? childSupportPaymentAmounts : null,
            laa_SpousalSupportPaymentAmounts: spousalSupportPaymentAmounts && isClientHaveDeductions && isFinancialsRequired ? spousalSupportPaymentAmounts : null,
            laa_ChildCarePaymentAmounts: childCarePaymentAmounts && isClientHaveDeductions && isFinancialsRequired ? childCarePaymentAmounts : null,
            laa_FinancialNotes: financialNotes && isFinancialsRequired ? financialNotes : null,
            laa_HasPreferredCounsel: havePreferredCounsel,
            laa_PreferredCounsel: havePreferredCounsel ? preferredCounsel : null,
            laa_IsIndigenous: indigenousAncestry ? parseInt(indigenousAncestry.value) : null,
            laa_InCustody: isInCustody,
            laa_OrcaNumber: theORCANumber ? theORCANumber : null,
            laa_CustodyLocation: isInCustody ? parseInt(custodyLocation.value) : null,
            laa_HelpNeededOnAllActiveCharges: helpNeededOnAllActiveCharges,
            laa_LikelihoodofImprisonment: confirmLOIforCharges,
            laa_ConsentforDisclosure: isConsentedDCOrdering,
            laa_NextCourtDate: nextCourtDate ? new Date(nextCourtDate) : null,
            laa_DocketNumber: docketNumber ? docketNumber : null,
            laa_CourtLocation: courtLocationText ? courtLocationText : null,
            laa_Courtroom: courtRoom ? courtRoom : null,
            laa_GeneralComments: generalComments ? generalComments : null
        }

        return request;
    }

    function navigateToPage() {
        if (backPage == Constants.CLIENTFILEVIEWDETAILS)
            navigate(`${prepareNavigationParameters(null, null, null, "page=" + Constants.CLIENTFILEDASHBOARD)}`);
        else
            navigate(`${prepareNavigationParameters(null, null, null)}`);
    }
    const onCancelClicked = () => navigateToPage();

    if (isDataReady) {
        return (
            <Grid container style={{ padding: "10px 20px 0px 10px", margin: "80px 0px 30px 0px" }}>

                {isAuthenticated && (<>

                    <SubHeader
                        title="Client Gateway Form"
                        menuItemOneText=""
                        onMenuItemOneClicked={() => { }}
                        isMenuItemOneVisible={false}
                        isMenuItemOneDisabled={false}
                        menuItemTwoText=""
                        onMenuItemTwoClicked=""
                        isMenuItemTwoVisible={false}
                        isMenuItemTwoDisabled={false}
                        isTooltipVisible={isSubmit()}
                        tooltipTitle={tooltipText}
                        navItemOneText="Submit"
                        onNavItemOneClicked={Save}
                        isNavItemOneVisible={true}
                        isNavItemOneDisabled={isSubmit() || saveClicked ? true : false}
                        navItemTwoText="Submit & Close"
                        onNavItemTwoClicked={SaveAndClose}
                        isNavItemTwoVisible={true}
                        isNavItemTwoDisabled={isSubmit() || saveClicked ? true : false}
                        navItemThreeText="Cancel"
                        onNavItemThreeClicked={onCancelClicked}
                        isNavItemThreeVisible={true}
                        isNavItemThreeDisabled={false}
                        navItemFourText=""
                        onNavItemFourClicked={() => { }}
                        isNavItemFourVisible={false}
                        isNavItemFourDisabled={false} />
                    <StyledDialog
                        open={openContactInfoDialog}
                        onClose={onContactInfoClose}
                        onConfirmed={onContactInfoClose}
                        title={
                            <div style={{ fontSize: "1.8em", color: "#bf0d3e", textAlign: "center", lineHeight: "1.2"}}>
                                Updated Contact Information<br/>for the client is very important!
                            </div>
                        }
                        message={"Ask the client if there is a number LAA can leave messages at just in case we, or a lawyer, needs to get in contact with them after submitting this form."}
                        secondMessage={"Alternative phone numbers or places we can leave messages ARE ACCEPTED."}
                        confirmationText="Yes"               
                    />
                    <StyledDialog title="Alert!" open={openDCOrderingDialog} onClose={onDCOrderingClose}
                        message={"As we cannot split active charges, please direct the client to call so they can provide their consent on specific charges."}
                        onConfirmed={onDCOrderingClose} confirmationText="Yes" />
                    <StyledDialog title="Alert!" open={openHelpNeededActiveChargesDialog} onClose={onHelpNeededActiveChargesClose}
                        message={"As we cannot split active charges, please direct the client to call so they can provide their consent on specific charges."}
                        onConfirmed={onHelpNeededActiveChargesClose} confirmationText="Yes" />
                    <Grid container style={{ padding: "10px 20px 0px 20px", border: "1px solid #7F8284", borderRadius: "8px" }}>
                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal normal 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                            Legal Aid Alberta Client Gateway is for LAA clients with criminal charges. If you are dealing with a client who does not have a criminal matter, they have to apply with us directly at 1-866-845-3425. Our Contact Center is open from 8:15 a.m. to 4:15 p.m. Monday - Friday.
                        </Grid>
                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal normal 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                            Please ensure you record either a phone number and an email for the client so LAA can get in touch with the Client if needed.
                        </Grid>
                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal normal 15px/24px Segoe UI", letterSpacing: "0px", color: "#ff0000", opacity: "1", marginBottom: "10px" }}>
                            If you experience issues with the form please contact ClientGateway@legalaid.ab.ca
                        </Grid>
                        <Grid container style={{ margin: "0px 20px 20px 20px", padding: "10px 20px 0px 20px", border: "1px solid #7F8284", borderRadius: "8px", backgroundColor: readOnly ? "#ececec" : "" }}>
                            <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal normal 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                Please specify: Which form would you be filling today?
                            </Grid>
                            <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField
                                    label="Form Type"
                                    placeholder="Form Type"
                                    required={true}
                                    disabled={readOnly}
                                    select
                                    value={formType ? formType?.label : ""}
                                    handleChange={onFormTypeChanged}
                                    SelectProps={{ MenuProps: { style: { maxHeight: 250, }, } }}
                                >
                                    {formTypes
                                        .filter(option => !(option.label === "JP Bail Form" && account && account.idTokenClaims.roles[0].toString() === "RosterLimitedAccess"))
                                        .map(option => (
                                            <MenuItem key={option.value} value={option.label}>
                                            {option.label}
                                            </MenuItem>
                                        ))
                                    }
                                </SelectorTextField>
                            </Grid>
                            {(formType == "" || formType?.value == "851160000" || formType?.value == "851160001") && (
                                <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField label="Name" placeholder="Name" required={true} disabled style={{ backgroundColor: "#ececec" }}
                                        value={name} />
                                </Grid>)}
                            {(formType != "" && formType?.value == "851160003") && (<>
                                <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                    I confirm that I am making this request with the consent of my existing client as described below.
                                    We understand LAA financial and service eligibility rules must still be met before coverage will be provided.
                                    Requests for additional coverage of new charges or matters continue to be subject to LAA Policies and Rules including LAA’s Travel Policy.
                                    Please ensure you provide the client’s updated contact information (phone/email) in the event that the Client’s eligibility must be confirmed by our Contact Centre Team.<span style={{ color: "#d32f2f" }}>*</span>
                                </Grid>
                                <Grid item xs={12} md={12} style={{ margin: "-10px 0px 10px 0px" }}>
                                    <StyledSwitch size="small" checked={isCurrentCounselConsented} onChange={onCurrentCounselConsented} disabled={readOnly} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField label="Active Certificate Number" placeholder="Active Certificate Number" required={true} disabled={readOnly}
                                        multiline
                                        error={activeCertificateNumberMaxLengthError ? true : (activeCertificateNumberError ? true : null)}
                                        value={activeCertificateNumber}
                                        handleChange={onActiveCertificateNumberChanged}
                                        helperText={activeCertificateNumberMaxLengthError ? "Max. 100 characters are allowed." : (activeCertificateNumberError ? "Active Certificate Number is required." : null)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField label="Counsel Name" placeholder="Counsel Name" required={true} disabled style={{ backgroundColor: "#ececec" }}
                                        value={counselName} />
                                </Grid></>)}
                            <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                I confirm that I have provided the following information to this Client:<br />
                                (1) Legal Aid is not a free service and you will be expected to establish a monthly payment plan if you do not have one set up already.<br />
                                (2) The personal information you provide on this form may be shared within Legal Aid, including the Staff Duty Counsel Team, and with your preferred or appointed Counsel.<br />
                                <br />
                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField
                                        label="Does the Client Understand and Consent?"
                                        disabled={readOnly}
                                        required={true}
                                        select value={!clientConsentOptionsDisplay ? 'No' : clientConsentOptionsDisplay}
                                        handleChange={onClientConsentChanged}
                                    >
                                        {clientConsentOptionsMapping.map((option) => (
                                            <MenuItem key={option.name} value={option.value}>
                                                {option.value}
                                            </MenuItem>))
                                        }
                                    </SelectorTextField>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="First Name" placeholder="First Name" required={true} disabled style={{ backgroundColor: "#ececec" }}
                                    value={firstName} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Last Name" placeholder="Last Name" required={true} disabled style={{ backgroundColor: "#ececec" }}
                                    value={lastName} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <CustomDatePicker label="Date of Birth" placeholder="Date of Birth" required={true} disabled style={{ width: "275px", backgroundColor: "#ececec" }}
                                    value={dateOfBirth} handleOnChange={() => { }} />
                            </Grid>
                            <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                Can you provide the Client's CURRENT contact information below:<br />
                                (Reminder: providing an email or phone number will help ensure that the client's application does not stall due to missing information on their client profile.) <span style={{ color: "#d32f2f" }}>*</span>
                            </Grid>
                            <Grid item xs={12} md={12} style={{ margin: "-10px 0px 5px 0px" }}>
                                <StyledSwitch size="small" checked={isContatcInfoConsented} onChange={onContatcInfoConsentChanged} disabled={readOnly} />
                            </Grid>
                            {isContatcInfoConsented && (<>
                                <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField label="Phone Number" placeholder="Phone Number" disabled style={{ backgroundColor: "#ececec" }}
                                        value={phoneNumber} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField label="Email" placeholder="Email" disabled style={{ backgroundColor: "#ececec" }}
                                        value={email} />
                                </Grid>
                                {/* Does client have a fixed address? */}
                                <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                    Does client have a fixed address?
                                    <StyledSwitch testId="hasFixedAddress" size="small" checked={isHasFixedAddress} onChange={isHasFixedAddressChanged} disabled={readOnly} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField
                                        label="Address"
                                        placeholder="Address"
                                        // required={isHasFixedAddress}
                                        disabled={!isHasFixedAddress || readOnly}
                                        style={{ backgroundColor: (isHasFixedAddress || readOnly) ? '' : '#ececec' }}
                                        multiline
                                        error={addressMaxLengthError ? true : null}
                                        value={address}
                                        handleChange={onAddressChanged}
                                        helperText={addressMaxLengthError ? "Max. 100 characters are allowed." : null}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField
                                        label="City"
                                        placeholder="City"
                                        required={isHasFixedAddress}
                                        disabled={!isHasFixedAddress || readOnly}
                                        style={{ backgroundColor: (isHasFixedAddress || readOnly) ? '' : '#ececec' }}
                                        multiline
                                        error={cityMaxLengthError ? true : null}
                                        value={city}
                                        handleChange={onCityChanged}
                                        helperText={cityMaxLengthError ? "Only allow characters, hyphens, spaces with a maximum of 100." : null}
                                    />

                                </Grid>
                                <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isNote={true} label="Contact Notes" placeholder="Contact Notes" multiline rows={5} sx={{ width: "90%" }}
                                        error={contactNotesMaxLengthError ? true : null}
                                        value={contactNotes} disabled={readOnly}
                                        handleChange={onContactNotesChanged}
                                        helperText={contactNotesMaxLengthError ? "Max. 255 characters are allowed." : null} />
                                </Grid></>)}

                            {/* Financials required section */}
                            <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#c50f10", opacity: "1", marginBottom: "10px"}}>
                                Are new or updated financials required?
                                <StyledSwitch testId="financialsRequired" size="small" checked={isFinancialsRequired} onChange={isFiancialRequiredChanged} disabled={readOnly} />
                            </Grid>
                            {isFinancialsRequired && (
                                <Grid container>
                                    <Divider style={{ width: "100%", border: "1px solid #7F8284", marginBottom: "18px"}}></Divider>

                                    <Grid container>
                                        {/* question 2 - Family size*/}
                                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px"}}>
                                            <TextField
                                                label="Family Size (including the client)"
                                                required={true}
                                                disabled={readOnly}
                                                error={familySizeLimitError ? true : null}
                                                value={familySize}
                                                handleChange={onFamilySizeChanged}
                                                helperText={familySizeLimitError ? "Family size is required and it must be a numerical value." : null}
                                            />
                                        </Grid>

                                        <br />

                                        {/* question 3 - Marital Status */}
                                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                            <SelectorTextField
                                                label="Marital Status"
                                                placeholder="Marital Status"
                                                disabled={readOnly}
                                                required={true}
                                                select value={maritalStatus ? maritalStatus : ""}
                                                handleChange={onMaritalStatusChanged}
                                            >
                                                {maritalStatusOptionsMapping.map((option) => (
                                                    <MenuItem key={option.name} value={option.value}>
                                                        {option.value}
                                                    </MenuItem>))
                                                }
                                            </SelectorTextField>
                                        </Grid>

                                        <br />
                                    </Grid>

                                    <br />

                                    <Grid container>
                                        {/* question 4 - Client on AISH */}
                                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                            Client on AISH?
                                            <StyledSwitch testId="clientOnAISH" size="small" checked={isClientOnAISH} onChange={isClientOnAISHChanged} disabled={readOnly} />
                                        </Grid>
                                        {isClientOnAISH && (
                                            <Grid>
                                                {/* question 9 - Does client have assets */}
                                                <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                                    Does client have assets?
                                                    <StyledSwitch testId="haveAssets" size="small" checked={isHaveAssets} onChange={isHaveAssetsChanged} disabled={readOnly} />
                                                </Grid>
                                                {isHaveAssets &&
                                                    (<Grid container>
                                                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "20px" }}>
                                                            (Reminder: At least one field must be completed. If no assets, select ‘No’ above.)
                                                        </Grid>

                                                        <Grid container>
                                                            {/* question 10 - Real Estate Equity Value */}
                                                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                                <TextField
                                                                    label="Real Estate Equity Value ($)"
                                                                    required={!vehicleEquityValue && !liquidAssetsEquityValue}
                                                                    disabled={readOnly}
                                                                    error={realEstateEquityValueLimitError ? true : null}
                                                                    value={realEstateEquityValue}
                                                                    handleChange={onRealEstateEquityValueChange}
                                                                    helperText={realEstateEquityValueLimitError ? "Real Estate Equity Value is required and it must be a numerical value." : null} 
                                                                />
                                                            </Grid>

                                                            <br />

                                                            {/* question 11 - Vehicle Equity Value */}
                                                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                                <TextField 
                                                                    label="Vehicle Equity Value ($)"
                                                                    required={!realEstateEquityValue && !liquidAssetsEquityValue}
                                                                    disabled={readOnly}
                                                                    error={vehicleEquityValueLimitError ? true : null}
                                                                    value={vehicleEquityValue}
                                                                    handleChange={onVehicleEquityValueChange}
                                                                    helperText={vehicleEquityValueLimitError ? "Vehicle Equity Value is required and it must be a numerical value." : null}
                                                                />
                                                            </Grid>

                                                            <br />

                                                            {/* question 12 - Liquid Assets Equity Value */}
                                                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                                <TextField
                                                                    label="Liquid Assets Equity Value ($)"
                                                                    required={!realEstateEquityValue && !vehicleEquityValue}
                                                                    disabled={readOnly}
                                                                    error={liquidAssetsEquityValueLimitError ? true : null}
                                                                    value={liquidAssetsEquityValue}
                                                                    handleChange={onLiquidAssetsEquityValueChange}
                                                                    helperText={liquidAssetsEquityValueLimitError ? "Liquid Assets Equity Value is required and it must be a numerical value." : null}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>)
                                                }

                                                <br />
                                            </Grid>
                                        )}
                                        {!isClientOnAISH && (
                                            <Grid container>
                                                <br />
                                                <Grid container>
                                                    {/* question 5 - Client 30 Day Net Income */}
                                                    <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                        <TextField
                                                            label="Client 30 Day Net Income ($)"
                                                            required={true}
                                                            disabled={readOnly}
                                                            error={thirtyDayIncomeLimitError ? true : null}
                                                            value={thirtyDayIncome}
                                                            handleChange={onThirtyDayIncomeChanged}
                                                            style={{ marginLeft: '2px'}}
                                                            helperText={thirtyDayIncomeLimitError ? "Client 30 Day Net Income is required and it must be a numerical value." : null}
                                                        />
                                                    </Grid>

                                                    <br />

                                                    {/* question 6 - Client 12 Month Net Income */}
                                                    <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                        <TextField
                                                            label="Client 12 Month Net Income ($)"
                                                            required={true}
                                                            disabled={readOnly}
                                                            error={twelveMonthIncomeLimitError ? true : null}
                                                            value={twelveMonthIncome}
                                                            handleChange={onTwelveMonthIncomeChanged}
                                                            helperText={twelveMonthIncomeLimitError ? "Client 12 Month Net Income is required and it must be a numerical value." : null}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <br />

                                                <Grid container style={{ display: showPartnerQuestions ? 'flex' : 'none', marginTop: "20px"}}>
                                                    {/* question 7 - Partner 30 Day Net Income - if 3 marital status is Common Law/Married */}
                                                    <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                        <TextField
                                                            label="Partner 30 Day Net Income ($)"
                                                            required={showPartnerQuestions}
                                                            disabled={readOnly}
                                                            error={partnerThirtyDayIncomeLimitError ? true : null}
                                                            value={partnerThirtyDayIncome}
                                                            handleChange={onPartnerThirtyDayIncomeChanged}
                                                            helperText={partnerThirtyDayIncomeLimitError ? "Partner 30 Day Net Income is required and it must be a numerical value." : null}
                                                        />
                                                    </Grid>

                                                    <br />

                                                    {/* question 8 - Partner 12 Month Net Income if 3 marital status is Common Law/Married */}
                                                    <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                        <TextField
                                                            label="Partner 12 Month Net Income ($)"
                                                            required={showPartnerQuestions}
                                                            disabled={readOnly}
                                                            error={partnerTwelveMonthIncomeLimitError ? true : null}
                                                            value={partnerTwelveMonthIncome}
                                                            handleChange={onPartnerTwelveMonthIncomeChanged}
                                                            helperText={partnerTwelveMonthIncomeLimitError ? "Partner 12 Month Net Income is required and it must be a numerical value." : null}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                {/* question 9 - Does client have assets */}
                                                <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                                    Does client have assets?
                                                    <StyledSwitch testId="haveAssets" size="small" checked={isHaveAssets} onChange={isHaveAssetsChanged} disabled={readOnly} />
                                                </Grid>
                                                {isHaveAssets &&
                                                    (<>
                                                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "20px" }}>
                                                            (Reminder: At least one field must be completed. If no assets, select ‘No’ above.)
                                                        </Grid>

                                                        <Grid container>
                                                            {/* question 10 - Real Estate Equity Value */}
                                                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                                <TextField
                                                                    label="Real Estate Equity Value ($)"
                                                                    required={!vehicleEquityValue && !liquidAssetsEquityValue}
                                                                    disabled={readOnly}
                                                                    error={realEstateEquityValueLimitError ? true : null}
                                                                    value={realEstateEquityValue}
                                                                    handleChange={onRealEstateEquityValueChange}
                                                                    helperText={realEstateEquityValueLimitError ? "Real Estate Equity Value is required and it must be a numerical value." : null} 
                                                                />
                                                            </Grid>

                                                            <br />

                                                            {/* question 11 - Vehicle Equity Value */}
                                                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                                <TextField 
                                                                    label="Vehicle Equity Value ($)"
                                                                    required={!realEstateEquityValue && !liquidAssetsEquityValue}
                                                                    disabled={readOnly}
                                                                    error={vehicleEquityValueLimitError ? true : null}
                                                                    value={vehicleEquityValue}
                                                                    handleChange={onVehicleEquityValueChange}
                                                                    helperText={vehicleEquityValueLimitError ? "Vehicle Equity Value is required and it must be a numerical value." : null}
                                                                />
                                                            </Grid>

                                                            <br />

                                                            {/* question 12 - Liquid Assets Equity Value */}
                                                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                                <TextField
                                                                    label="Liquid Assets Equity Value ($)"
                                                                    required={!realEstateEquityValue && !vehicleEquityValue}
                                                                    disabled={readOnly}
                                                                    error={liquidAssetsEquityValueLimitError ? true : null}
                                                                    value={liquidAssetsEquityValue}
                                                                    handleChange={onLiquidAssetsEquityValueChange}
                                                                    helperText={liquidAssetsEquityValueLimitError ? "Liquid Assets Equity Value is required and it must be a numerical value." : null}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </>)
                                                }

                                                <br />

                                                {/* question 13 - Does client have monthly deductions */}
                                                <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                                    Does client have monthly deductions?
                                                    <StyledSwitch testId="clientHaveDeductions" size="small" checked={isClientHaveDeductions} onChange={isClientHaveDeductionsChanged} disabled={readOnly} />
                                                </Grid>

                                                {isClientHaveDeductions && (
                                                    
                                                    <Grid container>
                                                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "20px" }}>
                                                            (Reminder: At least one field must be completed. If no deductions, select ‘No’ above.)
                                                        </Grid>

                                                        <Grid container>
                                                            {/* question 14 - Monthly Child Support Payment Amounts - if 13 is 'Yes' */}
                                                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                                <TextField
                                                                    label="Monthly Child Support Payment Amounts ($)"
                                                                    required={!spousalSupportPaymentAmounts && !childCarePaymentAmounts}
                                                                    disabled={readOnly}
                                                                    error={childSupportPaymentAmountsLimitError ? true : null}
                                                                    value={childSupportPaymentAmounts}
                                                                    handleChange={onChildSupportPaymentAmountsChange}
                                                                    helperText={childSupportPaymentAmountsLimitError ? "Monthly Child Support Payment Amounts is required and it must be a numerical value." : null}
                                                                />
                                                            </Grid>

                                                            <br />

                                                            {/* question 15 - Monthly Spousal Support Payment Amounts - if 13 is 'Yes' */}
                                                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                                <TextField
                                                                    label="Monthly Spousal Support Payment Amounts ($)"
                                                                    required={!childSupportPaymentAmounts && !childCarePaymentAmounts}
                                                                    disabled={readOnly}
                                                                    error={spousalSupportPaymentAmountsLimitError ? true : null}
                                                                    value={spousalSupportPaymentAmounts}
                                                                    handleChange={onSpousalSupportPaymentAmountsChange}
                                                                    helperText={spousalSupportPaymentAmountsLimitError ? "Monthly Spousal Support Payment Amounts is required and it must be a numerical value." : null}
                                                                />
                                                            </Grid>

                                                            <br />

                                                            {/* question 16 - Monthly Child Care Payment Amounts - if 13 is 'Yes' */}
                                                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                                <TextField
                                                                    label="Monthly Child Care Payment Amounts ($)"
                                                                    required={!childSupportPaymentAmounts && !spousalSupportPaymentAmounts}
                                                                    disabled={readOnly}
                                                                    error={childCarePaymentAmountsLimitError ? true : null}
                                                                    value={childCarePaymentAmounts}
                                                                    handleChange={onChildCarePaymentAmountsChange}
                                                                    helperText={childCarePaymentAmountsLimitError ? "Monthly Child Care Payment Amounts is required and it must be a numerical value." : null}
                                                                />
                                                            </Grid>

                                                            <br />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>

                                    {/* Financial Notes */}
                                    <Grid item xs={12} md={12} style={{marginTop: "20px"}}>
                                        <TextField
                                            isNote={true}
                                            label="Financial Notes"
                                            placeholder="Financial Notes"
                                            multiline rows={5} sx={{ width: "90%" }}
                                            error={financialNotesMaxLengthError ? true : null}
                                            value={financialNotes}
                                            handleChange={onFinancialNotesChanged}
                                            helperText={financialNotesMaxLengthError ? "Max. 300 characters are allowed." : null}
                                        />
                                    </Grid>

                                    <Divider style={{ width: "100%", border: "1px solid #7F8284", margin: "18px 0 10px 0" }}></Divider>
                                </Grid>
                            )}
                            <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                I confirm that I have explained to this Client that they can provide me with the names of up to three lawyers to choose as their Preferred Counsel if LAA approves coverage on this matter.<br />
                            </Grid>
                            <Grid item xs={4} sm={6} md={6} lg={4} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", margin: "-10px 0px 5px 0px" }}>
                                Does the Client have Preferred Counsel?
                                <StyledSwitch size="small" checked={havePreferredCounsel} onChange={onHavePreferredCounselChanged} disabled={readOnly} />
                            </Grid>
                            {havePreferredCounsel && (
                                <Grid item xs={8} md={8} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField label="Preferred Counsel" placeholder="Preferred Counsel" required={true} disabled={readOnly}
                                        error={preferredCounselMaxLengthError ? true : (preferredCounselError ? true : null)}
                                        multiline
                                        value={preferredCounsel}
                                        handleChange={onPreferredCounselChanged}
                                        helperText={preferredCounselMaxLengthError ? "Max. 100 characters are allowed." : (preferredCounselError ? "Preferred Counsel is required." : null)} />
                                </Grid>)}
                            <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1" }}>
                                Does the client self-identify as Indigenous?<br />
                            </Grid>
                            <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal normal 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                (Proper identification allows LAA to better understand client legal need and our ability to provide appropriate services.)
                            </Grid>
                            <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Indigenous Ancestry" placeholder="Indigenous Ancestry" disabled={readOnly}
                                    select
                                    value={indigenousAncestry ? indigenousAncestry?.label : ""}
                                    handleChange={onIndigenousAncestryChanged} >
                                    {indigenousAncestries.map((option) => (
                                        <MenuItem key={option.value} value={option.label}>
                                            {option.label}
                                        </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                    In Custody?
                                    <StyledSwitch size="small" checked={isInCustody} onChange={onInCustodyChanged} disabled={readOnly} />
                                </Grid>
                                {isInCustody && (
                                    <>
                                        {/* <SelectorTextField label="Custody Location" placeholder="Custody Location" required={true} disabled={readOnly}
                                        select
                                        value={custodyLocation ? custodyLocation?.label : ""}
                                        handleChange={onCustodyLocationChanged}
                                        SelectProps={{ MenuProps: { style: { maxHeight: 250, }, } }}>
                                        {custodyLocations.map((option) => (
                                            <MenuItem key={option.value} value={option.label}>
                                                {option.label}
                                            </MenuItem>))}
                                        </SelectorTextField> */}
                                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ margin: "15px 0 0 0" }}>
                                            <TextField
                                                label="ORCA Number"
                                                required={false}
                                                disabled={readOnly}
                                                error={theORCANumberLimitError ? true : null}
                                                value={theORCANumber}
                                                handleChange={onORCANumberChange}
                                                helperText={theORCANumberLimitError ? "ORCA Number must be a numerical value and maximum 12 digits." : null}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ margin: "15px 0 0 0" }}>
                                            <AutoCompleteTextField label="Custody Location" required={true}
                                                options={custodyLocations}
                                                value={arryOfInputCustodyLocations}
                                                onChange={onCustodyLocationChanged}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                Help Needed on All Active Charges?
                            </Grid>
                            <Grid item xs={12} md={12} style={{ margin: "-10px 0px 5px 0px" }}>
                                <StyledSwitch size="small" checked={helpNeededOnAllActiveCharges} onChange={onHelpNeededOnAllActiveChargesChanged} disabled={readOnly} />
                            </Grid>
                            {(

                                // not submit - all other roles should be able to see or click -> except limited role
                                (readOnly === false && account.idTokenClaims.roles[0] && account.idTokenClaims.roles[0] !== "RosterLimitedAccess")
                                // submitted - should see the field - no matter what the current role is, as long as the one who submitted is not limited role
                                || (
                                    readOnly === true &&
                                    account.idTokenClaims.roles[0] &&
                                    parseInt(getSubmittedFormSource) !== 851160007
                                )
                            ) && (<>
                                <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                    Can you confirm a reasonable Likelihood of Imprisonment for the charges/court locations selected below:
                                </Grid>
                                <Grid item xs={12} md={12} style={{ margin: "-10px 0px 5px 0px" }}>
                                    <StyledSwitch size="small" checked={confirmLOIforCharges} onChange={onconfirmLOIforChargesChanged} disabled={readOnly} />
                                </Grid>
                                {(confirmLOIforCharges == false) && (<>
                                    <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                        If the client's matter needs to be reviewed by the Staff Duty Counsel Team for service eligibility, does the Client consent to DC ordering disclosure on their behalf? <br />
                                        (Reminder: if you cannot obtain consent for disclosure, the DC Triage Lawyer will need to obtain consent which may delay review of their matter for LOI)
                                    </Grid>
                                    <Grid item xs={12} md={12} style={{ margin: "-10px 0px 5px 0px" }}>
                                        <StyledSwitch size="small" checked={isConsentedDCOrdering} onChange={onConsentedDCOrderingChanged} disabled={readOnly} />
                                    </Grid></>)}
                            </>)}
                            <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <CustomDatePicker label="Next Court Date" placeholder="Next Court Date"
                                    error={isNextCourtDateInvalid ? true : null} disabled={readOnly}
                                    value={nextCourtDate}
                                    handleOnChange={onNextCourtDateChanged}
                                    helperText={isNextCourtDateInvalid ? "Invalid Date. (Hint: dd-Mmm-yyyy)" : null} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Docket Number" placeholder="Docket Number" disabled={readOnly}
                                    multiline
                                    error={docketMaxLengthError ? true : null}
                                    value={docketNumber}
                                    handleChange={onDocketNumberChanged}
                                    helperText={docketMaxLengthError ? "Max. 100 characters are allowed." : null} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <AutoCompleteTextField label="Court Location" disabled={readOnly} multiple={true}
                                    options={courtLocations}
                                    value={arryOfInputValues}
                                    onChange={onCourtLocationsChanged} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Court Room" placeholder="Court Room" disabled={readOnly}
                                    multiline
                                    error={courtRoomMaxLengthError ? true : null}
                                    value={courtRoom}
                                    handleChange={onCourtRoomChanged}
                                    helperText={courtRoomMaxLengthError ? "Max. 100 characters are allowed." : null} />
                            </Grid>
                            <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField isNote={true} label="General Comments" placeholder="General Comments" multiline rows={3} sx={{ width: "90%" }} disabled={readOnly}
                                    error={generalCommentsMaxLengthError ? true : null}
                                    value={generalComments}
                                    handleChange={onGeneralCommentsChanged}
                                    helperText={generalCommentsMaxLengthError ? "Max. 300 characters are allowed." : null} />
                            </Grid>
                        </Grid>
                    </Grid>
                </>)}
            </Grid>
        );
    } else {
        return (<Backdrop />);
    }
}


export default ClientGatewayForm;